import type { WhiteLabelAction } from "./consts";
import type { OnboardingStep } from "components/routes/dashboard/GetStartedPage/onboardingSteps";

export type WhiteLabelState = {
  status: "new" | "loading" | "failed" | "none"
} | {
  status: "ready"
  fullLogoUrl: string
  squareLogoUrl: string
  companyName: string
  terms?: string
  onboardingSteps?: OnboardingStep[]
}

type WhiteLabelActionHandlers = {
  [K in WhiteLabelAction["type"]]: (state: WhiteLabelState, action: Extract<WhiteLabelAction, {type: K}> ) => WhiteLabelState
}


const ACTION_HANDLERS: WhiteLabelActionHandlers = {
  WHITE_LABEL_REQUEST_STARTED: () => ({ status: "loading" }),
  WHITE_LABEL_REQUEST_FAILED: () => ({ status: "failed" }),
  WHITE_LABEL_REQUEST_SUCCESS: (prevState, payload) => ({
    status: "ready",
    ...payload,
  }),
  WHITE_LABEL_REQUEST_SUCCESS_EMPTY: () => ({ status: "none" }),
};

const initialState: WhiteLabelState = {
  status: "new",
} as const;

export default function whiteLabelReducer(state = initialState, action: WhiteLabelAction) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state;
}
