import { getWhiteLabel } from "store/whiteLabel/selectors";
import store from "store/store";
import { NeverError } from "@gs/core/errors/NeverError";

export type OnboardingContentHeader = {
  type: "header"
  text: string
}
export type OnboardingContentParagraph = {
  type: "paragraph"
  text: string
}
export type OnboardingContentUnorderedList = {
  type: "unordered-list"
  items: string[]
}
export type OnboardingContentVideo = {
  type: "video"
  src: string
}

export type OnboardingStepContent = Array<OnboardingContentHeader | OnboardingContentParagraph | OnboardingContentUnorderedList | OnboardingContentVideo>
export type OnboardingStep = {
  title: string
  content: OnboardingStepContent
}

export const getSalesGetStartedSteps: OnboardingStep[] = [
  {
    title: "Welcome",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/835aef32013a47ef9ad79f2ef8cacec1",
      },
      {
        type: "header",
        text: "Welcome to GetSales",
      },
      {
        type: "paragraph",
        text: "Welcome to GetSales! We’re excited to guide you through our onboarding process, which will cover all the essential steps to harness the platform’s full potential. From setting up accounts to exploring automations and CRM tools, this journey is designed to help you understand every feature GetSales offers to make your outreach more effective and seamless.",
      },
      {
        type: "paragraph",
        text: "Follow along to gain insight into how our platform transforms B2B lead generation and creates a smooth workflow for you and your team.",
      },
      {
        type: "header",
        text: "To begin with",
      },
      {
        type: "paragraph",
        text: "In this series, you’ll start by connecting LinkedIn and email accounts, followed by saving leads, and finally building your first automations. Each step is tailored to give you hands-on knowledge and ensure that GetSales aligns with your outreach goals.",
      },
      {
        type: "paragraph",
        text: "In this onboarding, you’ll learn how to:",
      },
      {
        type: "unordered-list",
        items: [
          "Connect your LinkedIn account",
          "Link your email inbox",
          "Save Contacts & Imports and organize them within GetSales",
          "Add Contacts to Automation sequences",
          "ild your first automation for outreach",
          "Use the Unified Inbox for a consolidated view of interactions",
        ],
      },
      {
        type: "paragraph",
        text: "Ready to dive in? Let’s get started!",
      },
    ],
  },
  {
    title: "Single Session Protection",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/1d4f2502d5084af9a8176be20661c9ec",
      },
      {
        type: "header",
        text: "Steps to connect LinkedIn securely",
      },
      {
        type: "paragraph",
        text: "In this step-by-step guide, you’ll follow the setup process for connecting LinkedIn, whether you’re new to GoLogin or an experienced user. We’ll cover everything from downloading and installing GoLogin to creating secure sessions and selecting proxy settings for safer LinkedIn automation.",
      },
      {
        type: "paragraph",
        text: "If you’re using GoLogin for the first time, this video will walk you through downloading, signing up, and setting up a protected session where GetSales can safely manage your LinkedIn automation.",
      },
    ],
  },
  {
    title: "Connect LinkedIn Account",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/2cd7fd4470ad4eeab4094d0f15ce22f7",
      },
      {
        type: "header",
        text: "Connect LinkedIn account",
      },
      {
        type: "paragraph",
        text: "Connecting your LinkedIn account to GetSales is the first essential step, and we’ve introduced advanced security measures to protect your LinkedIn profile. In this video, you’ll learn about the GoLogin Antidetect browser, a tool designed to create a secure session for LinkedIn automation, which prevents potential risks and keeps your profile safe from LinkedIn’s detection systems. Instead of the previous method of entering credentials directly, this new approach allows you to manage outreach without fear of profile restrictions.",
      },
      {
        type: "paragraph",
        text: "This session helps protect your LinkedIn activities while making it easier to share access with team members or clients. The advanced protection means that you’ll be able to fully leverage LinkedIn outreach without compromising security, ensuring a safe environment for all automated actions.",
      },
    ],
  },
  {
    title: "Connect Gmail Inbox",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/c1539c61696c4108b0332fb308e6b5b9",
      },
      {
        type: "header",
        text: "Connect Gmail Inbox",
      },
      {
        type: "paragraph",
        text: "Email is a powerful tool within GetSales, allowing you to reach leads through personalized automation. In this video, you’ll learn to link your Gmail or custom SMTP accounts, unlocking full email automation potential on the platform. We’ll guide you through enabling IMAP, setting up two-step verification, and generating an app password specifically for GetSales, ensuring a secure connection that avoids the need for your regular Gmail password.",
      },
      {
        type: "paragraph",
        text: "Once connected, GetSales manages your emails through dedicated IP addresses, increasing deliverability and reliability. For custom SMTP, we’ve included simple steps to enter your server credentials, so you can start email automation right away. This setup makes campaigns run smoothly, maintaining high engagement rates and allowing you to track every email interaction effortlessly.",
      },
    ],
  },
  {
    title: "Custom SMTP Inbox",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/64cf4f0b648d4519ac68c56109334d42",
      },
      {
        type: "header",
        text: "Custom SMTP Inbox",
      },
      {
        type: "paragraph",
        text: "If you’re using a custom email provider, this video details how to connect nearly any SMTP-compatible inbox to GetSales. We support hundreds of email providers, automatically filling in metadata for most accounts, but if your provider isn’t in our system, you’ll find step-by-step instructions for manual configuration. Simply enter your login credentials and SMTP/IMAP information, and GetSales will take care of the rest.",
      },
      {
        type: "paragraph",
        text: "This versatile setup ensures a secure, reliable inbox connection for your campaigns, whether you’re managing one account or multiple inboxes. With everything linked, you’re ready to start personalized outreach with full control over your email communication.",
      },
    ],
  },
  {
    title: "Save Contacts & Imports",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/0680c44af54b410d86d50a86c9ae2e37",
      },
      {
        type: "header",
        text: "Save Contacts & Imports",
      },
      {
        type: "paragraph",
        text: "With your LinkedIn and email accounts connected, it’s time to populate GetSales with valuable leads. In this video, you’ll explore four different methods to save contacts & imports into the platform: LinkedIn scraping, Sales Navigator imports, CSV uploads, and the GetSales Chrome extension. Each option gives you flexibility in gathering contacts based on your preferred methods and resources.",
      },
      {
        type: "paragraph",
        text: "Using LinkedIn scraping, you can quickly import up to 1,000 leads from a LinkedIn search, while Sales Navigator lets you target high-quality leads with more advanced filters. Alternatively, importing CSV files allows you to enrich external leads with data such as names, company details, and recent posts. For quick individual saves, the Chrome extension lets you add leads directly from LinkedIn profiles.",
      },
      {
        type: "paragraph",
        text: "Each lead undergoes an enrichment process, where GetSales automatically adds additional data points like experience, skills, and education, enabling you to build highly targeted campaigns. This setup allows you to organize, personalize, and prepare leads for automation effectively.",
      },
      {
        type: "paragraph",
        text: "This guide will enable you to manage your outreach efficiently, track conversion rates, and adjust campaigns as needed, ensuring that each lead receives tailored attention throughout the automation process.",
      },
    ],
  },
  {
    title: "Add Contacts to Automation",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/9ca6fd26ad254ca8a588723da89f43f7",
      },
      {
        type: "header",
        text: "Add Contacts to Automation",
      },
      {
        type: "paragraph",
        text: "Adding leads to automations helps maintain organized and relevant outreach. In this video, we’ll walk you through multiple methods of adding leads: manually, from CRM integration, or via auto-filter. You can easily segment leads into target lists, assign specific sender profiles, and apply rules for smooth automation.",
      },
      {
        type: "paragraph",
        text: "This process allows you to control daily lead entry into a campaign, avoiding contact overload and ensuring personalized interactions. Segmenting leads helps refine messaging and timing, boosting connection success. Whether your lead flow is small or large, this video gives you the tools to manage it efficiently.",
      },
    ],
  },
  {
    title: "Build Robust Automation",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/c7279712caf94535ae79e63143416835",
      },
      {
        type: "header",
        text: "Build Robust Automation",
      },
      {
        type: "paragraph",
        text: "Now that your leads are uploaded and you understand the segment structure (or how to add leads), it’s time to create your first automation. Automations are the backbone of GetSales, offering a powerful way to manage large-scale campaigns while maintaining personalized outreach. In this video, you’ll learn how to set up segmentation for specific lead groups, add social actions, and configure follow-ups for optimal engagement.",
      },
      {
        type: "paragraph",
        text: "You can create individual automations for each lead segment or combine multiple segments into a single flow. This setup provides flexibility and allows you to test different approaches, such as A/B testing connection requests, to see which messaging drives better responses. You’ll also explore adding social activities, like profile views or endorsements, to increase engagement and personalize interactions.",
      },
    ],
  },
  {
    title: "Check Unified Inbox",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/b05cf89992fe4918a47c601d30f97ea7",
      },
      {
        type: "header",
        text: "Check Unified Inbox",
      },
      {
        type: "paragraph",
        text: "Your first automation is live, and replies are starting to come in. The Unified Inbox provides an organized space to track every response from leads across LinkedIn and email, helping you keep conversations on point. This video covers how to navigate the Inbox, where leads are sorted by status — such as “Engaging” or “Opportunity”— based on their communication depth.",
      },
      {
        type: "paragraph",
        text: "This full sync feature shows all past lead interactions in one place, including last message details, channel, and lead status. It lets you prioritize follow-ups and manage responses efficiently.",
      },
    ],
  },
  {
    title: "CRM Overview",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/e7466734f3624495ab2a0c1b557d4ac3",
      },
      {
        type: "header",
        text: "CRM Overview",
      },
      {
        type: "paragraph",
        text: "The GetSales CRM is your central hub for managing all lead data and outreach activities, designed to keep your workflow organized and effective. In this video, you’ll explore how to manage cold leads, track previous communications, and use advanced filters to segment leads based on job title, location, activity, and more. These features allow you to create highly personalized outreach, quickly responding to leads while maintaining consistency in communication.",
      },
      {
        type: "paragraph",
        text: "Additionally, this video demonstrates how to leverage automation data within the CRM, such as tracking which leads have responded or engaged with specific actions. With this information at your fingertips, you can refine your outreach strategy, ensuring that each lead follows a clear path through the sales funnel. This video equips you with the skills to stay organized, monitor each lead’s journey, and prioritize high-potential opportunities in real-time.",
      },
    ],
  },
  {
    title: "CRM Advanced",
    content: [
      {
        type: "video",
        src: "https://www.loom.com/embed/f044f5a2e70840f3a3e19155ff01a457",
      },
      {
        type: "header",
        text: "CRM Advanced",
      },
      {
        type: "paragraph",
        text: "This second video focuses on task management and pipeline progression within the GetSales CRM, showcasing how to move leads through different stages and maintain steady follow-ups. You’ll learn to create manual or automatic tasks for follow-ups, assign tasks to team members, and use tags and pipeline stages to track lead progress accurately. For instance, setting pipeline stages like “replied positive” or “opportunity” allows you to categorize leads and manage follow-ups more strategically.",
      },
      {
        type: "paragraph",
        text: "Looking ahead, GetSales is rolling out additional CRM updates, including account-based management features. These will let you oversee communication depth across multiple contacts within the same account, offering a higher-level strategic view of each lead’s journey. This enhanced feature will streamline your lead management process, enabling more precise control and helping you advance leads through the pipeline efficiently.",
      },
    ],
  },
];

export const useOnboardingSteps = (): null | OnboardingStep[] => {
  const whiteLabel = getWhiteLabel(store.getState());

  const whiteLabelStatus = whiteLabel.status;
  switch (whiteLabelStatus) {
    case "ready": {
      return whiteLabel.onboardingSteps || null;
    }
    case "failed":
    case "none": return getSalesGetStartedSteps;
    case "new":
    case "loading": return null;
    default: throw new NeverError(whiteLabelStatus);
  }
};
