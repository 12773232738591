import find from "lodash/find";
import { stepTypes } from "./consts";
import { loadTemplateByUuid, getTemplates } from "store/entities/templates/actions";
import { loadIntegrations } from "store/entities/integrations/actions";
import { loadMailboxes } from "store/entities/mailboxes/actions";
import { loadLists } from "store/entities/lists/actions";
import { loadTags, createTag } from "store/entities/tags/actions";
import { getTeamOptions } from "store/entities/users/actions";
import { icons } from "components/routes/flowsGroup/Flow/Details/visualConsts";
import store from "store/store";
import { checkPeriodTypes } from "./consts";
import { getSenderProfilesOptionsWithAvatar as getSenderProfilesOptions } from "store/entities/senderProfiles/actions";
import { getPipelineStages } from "store/entities/pipelineStages/actions";
import { flowApi } from "domain/flow/flowApi";

export const fieldTypes = {
  COMPOSITE: "COMPOSITE",
  SELECT: "SELECT",
  DELAY: "DELAY",
  SUBTASK_DELAY: "SUBTASK_DELAY",
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  RADIO: "RADIO",
  CHECK: "CHECK",
  SNIPPET: "SNIPPET",
  EMAIL: "EMAIL",
  INMAIL: "INMAIL",
  AB: "AB",
  FILTERS: "FILTERS",
  QUALIFICATION: "QUALIFICATION",
  MULTISELECT: "MULTISELECT",
  // CONDITION: 'CONDITION',
};

const serverActionKeys = {
  SEND_EMAIL: "email_send_message",
  SEND_INMAIL: "linkedin_send_inmail",
  MAKE_PHONE_CALL: "phone_call_phone",
  ADD_CUSTOM_TASK: "linkedin_visit_profile",
  ADD_SOCIAL_TASK: "social_task",
  SEND_LEAD_TO_OTHER_FLOW: "gs_send_to_flow",
  ADD_TO_LIST: "gs_change_list",
  REMOVE_FROM_LIST: "remove_from_list",
  ASSIGN_TO: "assign_to_user",
  CHANGE_FIELD_VALUE: "change_field_value",
  ADD_TAG: "gs_add_tag",
  REMOVE_TAG: "gs_remove_tag",
  WITHDRAW: "linkedin_withdraw_connection_request",
  CRM: "integration_export_to_crm",
  CHANGE_PIPELINE_STAGE: "gs_change_pipeline_stage",
  CHANGE_SENDER_PROFILE: "gs_change_sender_profile",

  SOCIAL_ACTIVITY: "linkedin_like_latest_post",
  SOCIAL_CONNECT: "linkedin_send_connection_request",
  SOCIAL_SEND_DM: "linkedin_send_message",
  SOCIAL_ENDORSE: "linkedin_endorse_skills",
  SOCIAL_COMMENT: "linkedin_comment_latest_post",
  FIND_CONTACT: "linkedin_find_contact",
};
/*
TODO: missed types:
linkedin_find_contact
 */

const serverTriggerKeys = {
  CHECK_REPLIED: "trigger_message_replied",
  CHECK_DM_REPLIED: "check_dm_replied",
  EMAIL_REPLIED: "email_replied",
  SOCIAL_CHECK_CONNECTED: "trigger_linkedin_connection_request_accepted",
  EMAIL_LINK_CLICKED: "trigger_link_clicked",
  EMAIL_OPENED: "trigger_email_opened",
  EMAIL_FORWARDED: "email_forwarded",
  FILE_DOWNLOADED: "file_downloaded",
  PAGE_VISITED: "trigger_website_page_visited",
};
export const serverRuleKeys = {
  A_B: "rule_ab_test",
  FILTERS: "rule_filter",
  QUALIFICATION: "rule_manual_router",
};
export const serverUtilKeys = {
  TIMER: "util_timer",
  HOLD: "util_hodor",
};

export const getNodeTypeByAction = (action) => {
  if (Object.values(serverActionKeys).includes(action)) return stepTypes.ACTION;
  if (Object.values(serverTriggerKeys).includes(action)) return stepTypes.TRIGGER;
  if (Object.values(serverRuleKeys).includes(action)) return stepTypes.RULE;
  if (Object.values(serverUtilKeys).includes(action)) return stepTypes.UTIL;
  return stepTypes.END;
};

export function getFirstServerNodeId(model, startUiNodeId) {
  let uiNode = find(model.nodes, { id: startUiNodeId });

  while (!uiNode.serverNodeId) {
    uiNode = find(model.nodes, { id: uiNode.after[0] });
  }

  return uiNode.serverNodeId;
}

const getCrmOptions = () => store.dispatch(loadIntegrations()).then((res) => res.data.map(({ type, uuid }) => ({
  value: uuid,
  label: type,
})));

const getEmailTemplatesOptions = ({ q = "" }) => Promise.resolve([])
  .then((r) => r.map( (template) => ({
    value: template.id,
    label: template.name,
    snippet: template.body,
    subject: template.subject,
    attachments: template.attachments,
    design: template.design,
  })));
const getSnippetsOptions = ({ q = "" }) => store.dispatch(getTemplates({ q }))
  .then((r) => r.data.map( (snippet) => ({
    value: snippet.id,
    label: snippet.name,
    snippet: snippet.body,
    snippet_dirty: snippet.body_dirty,
  })));

const automationOptions = () => Promise.resolve([
  {
    value: "auto",
    label: "Auto",
  }, {
    value: "manual",
    label: "Manual",
  },
]);

const getMailboxOptions = ({ q = "" }) => store.dispatch(loadMailboxes({ q }))
  .then((r) => r.map( (mailbox) => ({
    value: mailbox.id,
    label: mailbox.name || mailbox.email,
  })));

const getEntityValue = (entityId, entitiesName, loader, labelField = "name") => {
  const entity = store.getState().entities[entitiesName][entityId];
  if (entity) {
    return Promise.resolve({
      value: entity.id,
      label: entity[labelField],
      snippet: entity.body,
    });
  }

  return store.dispatch(loader(entityId))
    .then((entity) => ({
      value: entity.id,
      label: entity[labelField],
    }));
};

const getFlowsOptions = async ({ q = "" }) => {
  const { data: { data } } = await flowApi.getFlowsMemoized({
    limit: 200,
    offset: 0,
    filter: {
      query: q,
      status: ["on", "off"],
    },
  });
  const getOptionsByStatus = (status) => data
    .filter((flow) => flow.status === status)
    .map((flow) => ({ label: flow.name, value: flow.uuid }));
  return [
    {
      label: "running",
      options: getOptionsByStatus("on"),
    },
    {
      label: "paused",
      options: getOptionsByStatus("off"),
    },
  ];
};

const getListsOptions = ({ q = "", leadId }) => store.dispatch(loadLists(leadId))
  .then(({ data }) => data.map( (list) => ({
    value: list.uuid,
    label: list.name,
  })));

export const getPipelineOptions = ({ q = "", leadId }) => store.dispatch(getPipelineStages({ object: "lead", filters: {} }))
  .then((lists) => lists.filter((list) => list.type === "custom").map( (list) => ({
    value: list.uuid,
    label: list.name,
  })));

const getProfileOptions = () => getSenderProfilesOptions();

const getTagsOptions = ({ q = "" }) => store.dispatch(loadTags())
  .then((tags) => tags.map( (tag) => ({
    value: tag.uuid,
    label: tag.name,
  })));

const getUsersOptions = ({ q = "" }) => getTeamOptions({ withoutCurrent: true });


const getSendEmailSteps = ({ nodeId }) => {
  const model = store.getState().flowDetails.workTable;

  const sendEmailSteps = [];
  const loadingIds = [];
  const loadingPromises = [];

  const recursiveCheck = (nodeId) => {
    const curNode = find(model.nodes, { id: nodeId });

    for (const id of curNode.before) {
      recursiveCheck(id);
    }

    if (!curNode.serverNodeId) return;

    const curStep = find(model.flow.nodes, { id: curNode.serverNodeId });

    if (curStep.action !== serverActionKeys.SEND_EMAIL) return;

    const templateId = curStep.payload.template_id;
    const template = store.getState().entities.emailTemplates[templateId];

    if (template) {
      sendEmailSteps.push({
        value: curStep.id,
        label: template.name,
      });
    } else if (loadingIds.indexOf(templateId) === -1) {
      loadingIds.push(templateId);
      loadingPromises.push(
        store.dispatch(loadTemplateByUuid(templateId))
          .then( (template) => sendEmailSteps.push({
            value: curStep.id,
            label: template.name,
          })),
      );
    }
  };

  recursiveCheck(nodeId);

  return Promise.all(loadingPromises).then( () => sendEmailSteps );
};
const getPreviousNodesOfTypes = (actionTypes) => ({ nodeId }) => {
  const model = store.getState().flowDetails.workTable;

  const sendMessageSteps = [];
  const loadingPromises = [];
  const foundedIds = {};

  const recursiveCheck = (nodeId) => {
    const curNode = find(model.nodes, { id: nodeId });

    for (const id of curNode.before) {
      recursiveCheck(id);
    }

    if (!curNode.serverNodeId) return;

    const curStep = find(model.flow.nodes, { id: curNode.serverNodeId });

    if (actionTypes.indexOf(curStep.action) == -1) return;

    if (!foundedIds[curStep.id]) {
      foundedIds[curStep.id] = true;

      sendMessageSteps.push({
        value: curStep.id,
        label: curStep.id,
      });
    }
  };

  recursiveCheck(nodeId);

  return Promise.all(loadingPromises).then( () => sendMessageSteps );
};


export const getNodeOptions = () => {
  return store.getState().flowDetails?.workTable.flow.nodes.map((node) => {
    const nodeType = description[node.type][node.action];
    const label = nodeType ? nodeType.name : "End";
    return { label, value: node.id };
  });
};

const snippetField = {
  label: "Connect Message",
  key: "template",
  default: "",
  type: fieldTypes.SNIPPET,
  options: getSnippetsOptions,
};

const stopOnReplyTriggerLevels = {
  ALWAYS: "always",
  NODE: "flow_node",
  TIMEOUT: "time_shift",
  NEVER: "never",
};
const automationField = {
  label: "Automation",
  key: "automation",
  type: fieldTypes.RADIO,
  options: automationOptions,
  autoSelect: true,
  default: "auto",
};
const noteField = {
  label: "Note",
  key: "note",
  type: fieldTypes.TEXT,
  cond: (payload) => payload.automation === "manual",
  default: null,
};

const stopOnReplyTrigger = {
  label: "Send always, even if contact replied",
  key: "stop_on_reply_level",
  type: fieldTypes.CHECK,
  default: stopOnReplyTriggerLevels.ALWAYS,
  trueValue: stopOnReplyTriggerLevels.NEVER,
  falseValue: stopOnReplyTriggerLevels.ALWAYS,
  options: () => Promise.resolve([
    {
      value: stopOnReplyTriggerLevels.ALWAYS,
      label: "No",
    },
    {
      value: stopOnReplyTriggerLevels.NEVER,
      label: "Yes",
    },
  ]),
};

const description = {
  [stepTypes.UTIL]: {
    [serverUtilKeys.TIMER]: {
      name: "Time Delay",
      subtaskInfo: "Time the contact will wait for the trigger",
      icon: "time-delay",
      nodeHint: "This node will hold contacts who hit it for a specified period of time.",
      fields: [
        {
          label: "Wait",
          key: "wait_time",
          type: fieldTypes.DELAY,
          default: 60 * 60 * 24,
          minValue: 1,
        },
      ],
    },
    [serverUtilKeys.HOLD]: {
      name: "Open / Close Delay",
      icon: "social-activity",
      fields: [
        {
          label: "Open / Close Delay",
          key: "is_open",
          type: fieldTypes.RADIO,
          options: () => Promise.resolve([ {
            value: true,
            label: "Open",
          }, {
            value: false,
            label: "Close",
          },
          ]),
          autoSelect: false,
          default: true,
        },
      ],
    },
  },
  [stepTypes.ACTION]: {
    [serverActionKeys.SOCIAL_CONNECT]: {
      name: "Send Connection Request",
      icon: icons.LINKEDIN,
      nodeHint: "This node will send a connection request to all contacts who have passed through it. The request can be without text, but if there is text, it cannot exceed 300 characters.",
      fields: [
        automationField,
        noteField,
        {
          ...snippetField,
          maxLength: 300,
          withoutAttachments: true,
        },
        {
          label: "Make a connection without a message if the limit of personalized connections is reached",
          key: "fallback_send",
          default: true,
          type: fieldTypes.CHECK,
        },
      ],
    },
    [serverActionKeys.SOCIAL_SEND_DM]: {
      name: "Send Message",
      nodeHint: "This node will send in LinkedIn the message specified in it to all the contacts who have passed through it.",
      icon: icons.LINKEDIN,
      fields: [
        automationField,
        noteField,
        {
          label: "Message",
          key: "template",
          default: "",
          type: fieldTypes.SNIPPET,
          options: getSnippetsOptions,
        },
        stopOnReplyTrigger,
      ],
      triggers: [
        // {
        //   key: 'stop_on_reply',
        //   fields: [
        //     {
        //       key: 'level',
        //       label: 'Stop on reply',
        //       type: fieldTypes.SELECT,
        //       options: () => Promise.resolve([
        //         {
        //           value: stopOnReplyTriggerLevels.ALWAYS,
        //           label: 'Always',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.NODE,
        //           label: 'Message Node',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.TIMEOUT,
        //           label: 'Time Shift',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.NEVER,
        //           label: 'Never',
        //         },
        //       ]),
        //       default: stopOnReplyTriggerLevels.ALWAYS,
        //     },
        //     {
        //       key: 'flow_node_id',
        //       label: 'Message Node',
        //       type: fieldTypes.SELECT,
        //       options: getPreviousNodesOfTypes([serverActionKeys.SOCIAL_SEND_DM]),
        //       cond: (payload) => payload.triggers.stop_on_reply.level === stopOnReplyTriggerLevels.NODE,
        //     },
        //     {
        //       key: 'days',
        //       label: 'Days',
        //       type: fieldTypes.NUMBER,
        //       cond: (payload) => payload.triggers.stop_on_reply.level === stopOnReplyTriggerLevels.TIMEOUT,
        //       default: 7,
        //     },
        //   ],
        // },
      ],
    },
    [serverActionKeys.ADD_CUSTOM_TASK]: {
      name: "Visit Profile",
      nodeHint: "This node will visit LinkedIn page of all contacts who have passed through in.",
      icon: icons.LINKEDIN,
      fields: [
        automationField,
        noteField,
      ],
    },
    [serverActionKeys.SOCIAL_ACTIVITY]: {
      name: "Like Latest Post",
      icon: icons.LINKEDIN,
      nodeHint: "This node will leave a like under the last post for all contacts who have passed through it. If a contact doesn't have any post on page, this node will be skipped.",
      fields: [
        automationField,
        noteField,
      ],
    },
    [serverActionKeys.SOCIAL_ENDORSE]: {
      name: "Endorse Skills",
      icon: "linkedin-with-bg",
      subtaskInfo: "Number of skills to endorse",
      nodeHint: "This node will endorse the number of skills indicated in it for all contacts who have passed through it.",
      fields: [
        automationField,
        noteField,
        {
          label: "Skills count",
          key: "number",
          minValue: 1,
          maxValue: 10,
          type: fieldTypes.NUMBER,
          default: 1,
        },
      ],
    },
    [serverActionKeys.SOCIAL_COMMENT]: {
      name: "Comment Latest Post",
      icon: "linkedin-with-bg",
      subtaskInfo: "Text body for the comment",
      nodeHint: "This node will leave a comment with specified text under the last post for all contacts who have passed through it. If a contact does’t have any post on page, this node will be skipped.",
      fields: [
        automationField,
        noteField,
        {
          label: "Comment text",
          key: "template",
          default: "",
          type: fieldTypes.SNIPPET,
          options: getSnippetsOptions,
          withoutAttachments: true,
          isTemplates: false,
        },
      ],
    },
    [serverActionKeys.WITHDRAW]: {
      name: "Withdraw Connection Request",
      nodeHint: "This node will withdraw LinkedIn connection requests from all contacts who have passed through it. If the request wasn’t sent, this node will be skipped.",
      icon: icons.LINKEDIN,
      fields: [
        automationField,
        noteField,
        {
          label: "Automation type",
          key: "withdraw_key",
          type: fieldTypes.RADIO,
          cond: (payload) => !payload,
          options: () => Promise.resolve([ {
            value: "auto",
            label: "Auto Task",
          }, {
            value: "manual",
            label: "Manual Task",
          },
          ]),
          autoSelect: false,
          default: null,
        },
      ],
      proTips: [
        "After withdraw you can send a Connection Request again in 30 days",
        "If you don’t have sent Connection Request we will skip the task",
      ],
    },
    [serverActionKeys.SEND_EMAIL]: {
      name: "Send Email",
      icon: icons.SEND_EMAIL,
      nodeHint: "This node will send in mail the message specified in it to all the contacts who have passed through it.",
      fields: [
        {
          label: "Select contact email type",
          key: "email_field",
          type: fieldTypes.SELECT,
          options: () => Promise.resolve([{
            value: "work_email",
            label: "Send To Work Email",
          }, {
            value: "personal_email",
            label: "Send To Personal Email",
          }, {
            value: "",
            label: "Send To Personal And Work Email",
          }]),
          default: "work_email",
          getValueLabel: (tag) => Promise.resolve(tag),
          creatable: true,
          createFn: (name) => store.dispatch(createTag({ name })).then((tag) => tag.uuid),
        },
        automationField,
        noteField,
        // {
        //   label: 'Mailbox',
        //   key: 'mailbox_id',
        //   type: fieldTypes.SELECT,
        //   options: getMailboxOptions,
        //   getValueLabel: (id) => getEntityValue(id, 'mailboxes', loadMailbox, 'email'),
        // },
        {
          label: "Reply to previous thread",
          key: "add_history",
          type: fieldTypes.CHECK,
          default: true,
          trueValue: true,
          falseValue: false,
          options: () => Promise.resolve([
            {
              value: true,
              label: "Yes",
            },
            {
              value: false,
              label: "False",
            },
          ]),
        },
        {
          label: "Template",
          key: "template",
          default: "",
          type: fieldTypes.EMAIL,
          options: getEmailTemplatesOptions,
        },
        stopOnReplyTrigger,
      ],
      triggers: [
        // {
        //   key: 'stop_on_reply',
        //   fields: [
        //     {
        //       key: 'level',
        //       label: 'Stop on reply',
        //       type: fieldTypes.SELECT,
        //       options: () => Promise.resolve([
        //         {
        //           value: stopOnReplyTriggerLevels.ALWAYS,
        //           label: 'Always',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.NODE,
        //           label: 'Message Node',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.TIMEOUT,
        //           label: 'Time Shift',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.NEVER,
        //           label: 'Never',
        //         },
        //       ]),
        //       default: stopOnReplyTriggerLevels.ALWAYS,
        //     },
        //     {
        //       key: 'flow_node_id',
        //       label: 'Message Node',
        //       type: fieldTypes.SELECT,
        //       options: getPreviousNodesOfTypes([serverActionKeys.SEND_EMAIL]),
        //       cond: (payload) => payload.triggers.stop_on_reply.level === stopOnReplyTriggerLevels.NODE,
        //     },
        //     {
        //       key: 'days',
        //       label: 'Days',
        //       type: fieldTypes.NUMBER,
        //       cond: (payload) => payload.triggers.stop_on_reply.level === stopOnReplyTriggerLevels.TIMEOUT,
        //       default: 7,
        //     },
        //   ],
        // },
      ],
    },
    [serverActionKeys.SEND_INMAIL]: {
      name: "Send InMail",
      icon: icons.LINKEDIN,
      nodeHint: "This node will send in InMail to open profiles.",
      fields: [
        automationField,
        noteField,
        {
          label: "Template",
          key: "template",
          default: "",
          type: fieldTypes.INMAIL,
        },
      ],
      triggers: [],
    },
    [serverActionKeys.CRM]: {
      name: "Export to CRM",
      icon: "link",
      fields: [
        {
          label: "CRM",
          key: "integration_uuid",
          type: fieldTypes.SELECT,
          options: getCrmOptions,
        },
      ],
    },
    [serverActionKeys.ADD_TAG]: {
      name: "Add Tag",
      icon: icons.ADD_TO_LIST,
      nodeHint: "This node will assign the tags specified in it to all contacts who have passed through it. ",
      fields: [
        {
          label: "Tag",
          key: "tag_uuid",
          type: fieldTypes.SELECT,
          options: getTagsOptions,
          getValueLabel: (tag) => Promise.resolve(tag),
          creatable: true,
          createFn: (name) => store.dispatch(createTag({ name })).then((tag) => tag.uuid),
        },
      ],
    },
    [serverActionKeys.REMOVE_TAG]: {
      name: "Remove Tag",
      icon: icons.ADD_TO_LIST,
      nodeHint: "This node will assign the tags specified in it to all contacts who have passed through it. If the contact doesn’t have specified tags, it will pass on ",
      fields: [
        {
          label: "Tag",
          key: "tag_uuid",
          type: fieldTypes.SELECT,
          options: getTagsOptions,
          getValueLabel: (tag) => Promise.resolve(tag),
          creatable: true,
          createFn: (name) => store.dispatch(createTag({ name })).then((tag) => tag.uuid),
        },
      ],
    },
    [serverActionKeys.ASSIGN_TO]: {
      name: "Assign To",
      icon: icons.ASSIGN_TO,
      fields: [
        {
          label: "User",
          key: "user_id",
          type: fieldTypes.SELECT,
          options: getUsersOptions,
        },
        {
          label: "List",
          key: "list_id",
          type: fieldTypes.SELECT,
          options: ({ payload }) => getListsOptions({ leadId: payload.user_id }),
          cond: (paylaod) => paylaod.user_id,
        },
      ],
    },
    [serverActionKeys.SEND_LEAD_TO_OTHER_FLOW]: {
      name: "Send to Another Automation",
      icon: icons.SEND_CONTACT_IN_NEW_FLOW,
      nodeHint: "This node will send all contacts who have passed through it in another automation process. After sending contacts in another automation process, they will remain active in the current automation process as well.",
      fields: [
        {
          label: "New Automation",
          key: "flow_uuid",
          type: fieldTypes.SELECT,
          options: getFlowsOptions,
        },
      ],
    },
    [serverActionKeys.MAKE_PHONE_CALL]: {
      name: "Call Phone (coming soon)",
      icon: icons.PHONE_CALL,
      isDisabled: true,
      fields: [
        // {
        //   label: 'Automation',
        //   key: 'mode',
        //   type: fieldTypes.RADIO,
        //   options: () => Promise.resolve([ {
        //       value: 'manual',
        //       label: 'Manual',
        //     }
        //   ]),
        //   autoSelect: true,
        //   default: 'manual',
        // },
        {
          label: "Phone",
          key: "phone",
          type: fieldTypes.TEXT,
        },
      ],
    },
    [serverActionKeys.ADD_TO_LIST]: {
      name: "Change List",
      icon: "change-list",
      nodeHint: "This node will change the current list on another to all contacts who have gone through it ",
      fields: [
        {
          label: "List",
          key: "list_uuid",
          type: fieldTypes.SELECT,
          options: getListsOptions,
        },
      ],
    },
    [serverActionKeys.CHANGE_PIPELINE_STAGE]: {
      name: "Change Pipeline Stage",
      icon: "pipeline-change-activity",
      nodeHint: "This node will change the current Pipeline Stage on another to all contacts who have gone through it. ",
      fields: [
        {
          label: "Pipeline",
          key: "pipeline_stage_uuid",
          type: fieldTypes.SELECT,
          options: getPipelineOptions,
        },
      ],
    },
    [serverActionKeys.CHANGE_SENDER_PROFILE]: {
      name: "Change Sender Profile",
      icon: "sender-profiles",
      nodeHint: "This node will replace the mapped Sender Profile with another to all contacts who have passed through it. After that, all communication on those contacts will go on behalf of the other Sender Profile.",
      fields: [
        {
          label: "Sender Profile",
          key: "sender_profile_uuid",
          type: fieldTypes.SELECT,
          options: getProfileOptions,
        },
      ],
    },
    // [serverActionKeys.REMOVE_FROM_LIST]: {
    //   name: "Remove From List",
    //   icon: icons.REMOVW_FROM_LIST,
    //   fields: [
    //     {
    //       label: 'List',
    //       key: 'list_id',
    //       type: fieldTypes.SELECT,
    //       options: getListsOptions,
    //     },
    //     {
    //       label: 'Delay',
    //       key: 'wait_time',
    //       type: fieldTypes.DELAY,
    //     },
    //   ],
    // },
  },
  [stepTypes.RULE]: {
    [serverRuleKeys.FILTERS]: {
      name: "Condition Rules",
      icon: "condition-rules",
      nodeHint: "This node will distribute the contacts passed through it, into groups depending on filters specified in them. If contact does’t fit any of the specified filter, it will go through an alternate branch.",
      fields: [
        {
          label: "Filters",
          key: "branches",
          type: fieldTypes.FILTERS,
        },
      ],
    },
    [serverRuleKeys.A_B]: {
      name: "A/B Test",
      nodeHint: "This node will distribute the contacts passed through it, into two or more groups with specified proportions.",
      icon: "ab-test",
      fields: [
        {
          label: "Branches",
          key: "branches",
          type: fieldTypes.AB,
        },
      ],
    },
    [serverRuleKeys.QUALIFICATION]: {
      name: "Manual Router",
      icon: "noun-split",
      fields: [
        {
          label: "Branches",
          key: "branches",
          type: fieldTypes.QUALIFICATION,
        },
      ],
    },
  },
  [stepTypes.TRIGGER]: {
    [serverTriggerKeys.SOCIAL_CHECK_CONNECTED]: {
      name: "Connection Request Accepted",
      nodeHint: "This node will check for acceptance of a connection request. While contact is waiting it will go through subtasks. Time of waiting will be sum of all subtasks execution. If a contact accepts a connection request, it will immediately go to the branch \"Connected\", all who did not accept the request during subtasks execution will go to the branch \"No Connect\".",
      icon: icons.LINKEDIN,
      fields: [
      ],
      subtasks: [
        {
          type: stepTypes.UTIL,
          action: serverUtilKeys.TIMER,
          payload: {
            delay: 60,
          },
        },
        {
          type: stepTypes.ACTION,
          action: serverActionKeys.ADD_CUSTOM_TASK,
        },
        {
          type: stepTypes.ACTION,
          action: serverActionKeys.SOCIAL_ACTIVITY,
        },
        {
          type: stepTypes.ACTION,
          action: serverActionKeys.SOCIAL_COMMENT,
        },
      ],
      branchLabels: ["No Connect", "Connected"],
    },
    [serverTriggerKeys.CHECK_REPLIED]: {
      name: "Message Replied (any channel)",
      nodeHint: "This node will check for contact reply. While contact is waiting it will go through subtasks. Time of waiting will be sum of all subtasks execution. If a contact replied, it immediately will go to the branch \"Replied\", all who did not reply during subtasks execution will go to the branch \"No Reply\".",
      icon: "messages-new-black",
      subtasks: [
        {
          type: stepTypes.UTIL,
          action: serverUtilKeys.TIMER,
          payload: {
            delay: 60,
          },
        },
        {
          type: stepTypes.ACTION,
          action: serverActionKeys.ADD_CUSTOM_TASK,
        },
        {
          type: stepTypes.ACTION,
          action: serverActionKeys.SOCIAL_ACTIVITY,
        },
        {
          type: stepTypes.ACTION,
          action: serverActionKeys.SOCIAL_COMMENT,
        },
        {
          type: stepTypes.ACTION,
          action: serverActionKeys.SOCIAL_ENDORSE,
        },
      ],
      fields: [
        {
          key: "check_period_type",
          label: "Check reply to",
          type: fieldTypes.RADIO,
          PayloadComponent: ({ field, payload }) => {
            const labelsMap = {
              last_response: "Last Reply Message",
              [checkPeriodTypes.ALL_TIME]: "All Time",
            };
            return labelsMap[payload[field.key]] || "error";
          },
          options: () => Promise.resolve([
            {
              value: "last_response",
              label: "Last Reply Message",
            },
            {
              value: checkPeriodTypes.ALL_TIME,
              label: "Any Reply Message",
            },
          ]),
          default: "last_response",
        },
      ],
      branchLabels: ["No Reply", "Replied"],
    },
    [serverTriggerKeys.CHECK_DM_REPLIED]: {
      name: "Check Direct Message Replied",
      icon: icons.LINKEDIN,
      isHidden: true,
      fields: [
      ],
      branchLabels: ["No Reply", "Replied"],
    },
    [serverTriggerKeys.EMAIL_LINK_CLICKED]: {
      name: "Message Link Clicked (coming)",
      icon: icons.EMAIL_LINK_CLICK,
      isDisabled: true,
      fields: [
        {
          label: "Node",
          key: "node_id",
          type: fieldTypes.SELECT,
          options: getSendEmailSteps,
        },
      ],
    },
    [serverTriggerKeys.EMAIL_REPLIED]: {
      name: "Email Replied",
      icon: icons.EMAIL_LINK_CLICK,
      isHidden: true,
      fields: [
        // {
        //   label: 'Node',
        //   key: "node_id",
        //   type: fieldTypes.SELECT,
        //   options: getSendEmailSteps,
        // },
      ],
      branchLabels: ["No Reply", "Replied"],
    },
    [serverTriggerKeys.EMAIL_OPENED]: {
      name: "Email Opened (coming soon)",
      icon: icons.SEND_EMAIL,
      isDisabled: true,
      fields: [
        {
          label: "Node",
          key: "node_id",
          type: fieldTypes.SELECT,
          options: getSendEmailSteps,
        },
      ],
    },
    // [serverTriggerKeys.EMAIL_FORWARDED]: {
    //   name: "Email Forwarded",
    //   icon: icons.EMAIL_FORWARDED,
    //   fields: [
    //     {
    //       label: 'Node',
    //       key: "node_id",
    //       type: fieldTypes.SELECT,
    //       options: getSendEmailSteps,
    //     },
    //     {
    //       label: 'Delay',
    //       key: 'wait_time',
    //       type: fieldTypes.DELAY,
    //       default: 86400,
    //     },
    //   ],
    // },
    // [serverTriggerKeys.FILE_DOWNLOADED]: {
    //   name: "File Download",
    //   icon: icons.FILE_DOWNLOAD,
    //   fields: [
    //     {
    //       label: 'Node',
    //       key: "node_id",
    //       type: fieldTypes.SELECT,
    //       options: getSendEmailSteps,
    //     },
    //     {
    //       label: 'Delay',
    //       key: 'wait_time',
    //       type: fieldTypes.DELAY,
    //       default: 86400,
    //     },
    //   ],
    // },
    [serverTriggerKeys.PAGE_VISITED]: {
      name: "Website Page Visited (coming)",
      icon: icons.EMAIL_LINK_CLICK,
      isDisabled: true,
      fields: [
        {
          label: "URL Wildcard",
          key: "page",
          type: fieldTypes.TEXT,
          placeholder: "https://your.website.com/shop/*/buy",
        },
      ],
    },
  },
  [stepTypes.END]: {},
};

for (const nodeType of Object.values(description)) {
  for (const id in nodeType) {
    nodeType[id].id = id;
  }
}

export {
  description as default,
  description,
  serverActionKeys,
  serverTriggerKeys,
};
