import type { User } from "@gs/core/domain/User/User";
import { Button } from "@gs/uiKit/general/Button";
import { useState } from "react";
import { Flex } from "@gs/uiKit/layout/Flex";
import { sessionService } from "feature/session/SessionService";
import { userApi } from "domain/user/userApi";
import { getError, showError, showSuccess } from "services/utils";
import { Form } from "@gs/uiKit/input/Form";

export const StepEmail = (props: {user: User}) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const submit = async () => {
    try {
      await userApi.confirmEmail({
        code,
        email: props.user.email,
      });
      window.location.reload();
    } catch (theError) {
      setError(getError(theError));
    }
  };

  return (
    <div>
      <div className="gs-fs-28 gs-fw-500 gs-mb">
        Let’s confirm your email
      </div>

      <div className="gs-fs-16">
        We’ve just sent a 6-digit code to
        {" "}
        <span className="gs-fw-500">
          {props.user.email}
        </span>
        {/*<Button type="link" size="small">Change email</Button>*/}
      </div>
      <div className="gs-fs-16 gs-mb-xl">
        Pop the code in so we can verify you and keep your account secure
      </div>

      <Form.Item
        validateStatus={error ? "error" : undefined}
        help={error}
      >
        <OTP
          value={code}
          onChange={(newCode) => {
            setCode(newCode);
            setError("");
          }}
          className="gs-mb-sm"
        />
      </Form.Item>
      <div>
        <span className="gs-c-secondary">
          Didn't receive code?
        </span>
        {" "}
        <Button
          type="link"
          htmlType="button"
          size="small"
          className="gs-mb-xl"
          onClick={
            () => userApi.resendConfirmationEmail()
              .then(() => showSuccess("Email was code was successfully sent."))
              .catch(showError)
          }
        >
          Resend
        </Button>
      </div>
      <div className="gs-fs-16 gs-fw-500 gs-mb-xxs">
        Why do we ask for this?
      </div>
      <div className="gs-fs-16 gs-mb-lg">
        Verifying your email helps us ensure that you receive important updates and can easily recover your account if needed.
      </div>
      <div className="gs-fs-16 gs-fw-500 gs-mb-xxs">
        Can’t find the email?
      </div>
      <div className="gs-fs-16">
        It might’ve snuck into your spam or junk folder – check there!
      </div>

      <Button
        onClick={submit}
        type="primary"
        htmlType="submit"
        style={{ width: "100%" }}
        className="gs-mt-xl gs-mb"
      >
        Continue
      </Button>


      <div>
        <span className="gs-c-secondary">
          Need to switch user?
        </span>
        {" "}
        <Button
          type="link"
          htmlType="button"
          size="small"
          onClick={() => sessionService.signOut()}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

const OTP = (props: {value: string; onChange: (v: string) => void; className?: string}) => {
  const length = 6;

  return (
    <Flex
      justify="space-between"
      className={props.className}
    >
      {new Array(length).fill(true).map((_, index) => (
        <input
          value={(!props.value[index] || props.value[index] === " " ) ? "" : props.value[index]}
          onChange={(e) => {
            const arrValue: string[] = [];
            for (let i = 0; i < length; i++) {
              arrValue[i] = props.value[i] || " ";
            }
            arrValue[index] = e.target.value.replace(arrValue[index]!, "");
            props.onChange(arrValue.join(""));
            if (e.target.value) {
              // @ts-ignore
              e.target.nextElementSibling?.focus?.();
            } else {
              // @ts-ignore
              // e.target.previousElementSibling?.focus?.();
            }
          }}
          onKeyDown={(e) => {
            if (e.code === "Backspace") {
              // @ts-ignore
              e.target.previousElementSibling?.focus?.();
            }
          }}
          onPaste={(e) => {
            e.preventDefault();
            const text = e.clipboardData.getData("Text");

            props.onChange(text.slice(0, length));
          }}
          style={{
            width: 77,
            height: 77,
            fontSize: 32,
            textAlign: "center",
            border: "1.22px solid #E6E9EE",
            borderRadius: 22,
          }}
        />
      ))}
    </Flex>
  );
};
