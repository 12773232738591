import type { Dispatch } from "redux";
import getAxios from "../../services/axios";
import type { WhiteLabelAction } from "./consts";

const axios = getAxios("idV2");

export const loadWhiteLabelLogos = (domain: string) => async (dispatch: Dispatch<WhiteLabelAction>) => {
  dispatch({
    type: "WHITE_LABEL_REQUEST_STARTED",
  });

  try {
    const res = await axios.get(`/api/whitelabels/by-domain/${domain}`);

    if (!res) {
      return dispatch({
        type: "WHITE_LABEL_REQUEST_SUCCESS_EMPTY",
      });
    }

    dispatch({
      type: "WHITE_LABEL_REQUEST_SUCCESS",
      fullLogoUrl: res.logo,
      squareLogoUrl: res.square_logo,
      companyName: res.company_name,
      terms: res.config?.terms,
      onboardingSteps: res.config?.onboarding,
    });
  } catch {
    dispatch({
      type: "WHITE_LABEL_REQUEST_FAILED",
    });
  }
};
